import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProfileImage from "../components/profile-image"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "@material-ui/core/Typography"

const IndexPage = ({ data }) => {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
  }))

  const classes = useStyles()

  return (
    <Layout>
      <SEO title="" />
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ProfileImage />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Hi, I'm Scott!
            </Typography>
            <Typography variant="h5" paragraph>
              I'm a Canadian product manager currently living in Seattle, WA
              working on the Microsoft Viva team.
              To learn more, check out <Link to="/info/">my bio</Link>!
            </Typography>
            <Typography variant="h5" paragraph>
              Outside of work, you'll likely find me reading, coding, dabbling
              in the kitchen, taking photographs, or making pottery. I'm
              challenging myself to write more about the things I've learned,
              the books I've read, and the latest dishes I've cooked, so please
              feel free to check out my <Link to="/blog/">blog</Link>!
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export default IndexPage
